import React from "react";
import styles from "./css/Policy.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const SafetyPolicy = () => {
  AOS.init();

  return (
    <div className={styles.container}>
      <div className={styles.greeting_top_wrapper}>
        <img
          src="../imgs/img/greeting-top.png"
          alt=""
          className={styles.greeting_top_img}
        />
      </div>
      <div className={styles.section}>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.greeting_title}
        >
          안전보건 경영방침
        </div>
        <img
          data-aos="fade-up"
          data-aos-duration="2000"
          src={require("../../../assets/imgs/policy_2.png")}
          alt=""
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default SafetyPolicy;
