import React from "react";
import styles from "./css/BloodCollection.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import AOS from "aos";
import "aos/dist/aos.css";

const BloodCollection = () => {
  AOS.init();

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.section_title}
        >
          채혈지침
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.text_normal_500}
        >
          아래의 지침은 채혈을 담당하는 의사, 간호사, 임상병리사, 채혈을
          담당하는 모든 직원이 숙지하여야한다.
          <br />
          소아, 성인의 규정은 동일하며 아래의 지침에 준한다.
          <br />
          검체의 채취 및 취급은 적절한 검체채취와 취급에 관한 별도의 지침서가
          마련되어
          <br />
          진단검사의학과 근무자와 검체를 채취하는 모두에게 사용이 가능해야 하며{" "}
          <br />
          또한 채혈 업무는 환자의 권리와 사생활 보호에 관한 윤리강령과 환자
          권리장전에 명시된 내용을 숙지하고 이해하여야한다.
          <br />
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.sub_wrapper}
        >
          <div className={styles.sub_title_wrapper}>
            <div className={styles.rect} />
            <div className={styles.sub_title_text}>환자의 채혈업무</div>
          </div>
          <div className={styles.sub_text}>
            환자의 이름, 등록번호, 진료과, 진료일, 검사항목을 확인 한 후
            검사항목에 따른 채혈 가능여부를 환자에게 공지한다.
            <br />
            당일 채혈이 불가능한 환자는 채혈 날짜와 주의사항을 전달한 후 다음에
            채혈할 것을 알려주어야하며
            <br />
            당일검사가 가능한 항목은 채혈로 재차 병원을 방문하는 일이 없도록
            처리한다.
            <br />
            채혈과 소변검사가 같이 있는 경우, 바코드(환자인적사항)를 붙인 튜브에
            채혈한 후 소변컵에 바코드를 붙이고
            <br />
            소변을 받도록 하여 소변컵의 뇨를 검체를 놓는 장소에 두게 한다.
            <br />
            소변검사만 있는 경우, 소변컵에 바코드(환자인적사항)를 붙이고 채뇨 후
            뇨 검체 두는 장소에 놓으라고 안내한다.
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.sub_wrapper}
        >
          <div className={styles.sub_title_wrapper}>
            <div className={styles.rect} />
            <div className={styles.sub_title_text}>환자응대</div>
          </div>
          <div className={styles.sub_text}>
            의료기간 전 직원은 채혈실을 방문하는 모든 환자에게 최대의 자세한
            설명 및 친절한 안내로
            <br />
            불편함이 없도록 최선의 노력을 기울여야 한다.
          </div>
          <div className={styles.sub_num_wrapper}>
            <div className={styles.rect} />
            <div className={styles.sub_num_title}>
              1. 환자가 채혈실로 방문하였을 때 자세
            </div>
          </div>
          <div className={styles.sub_num_text}>
            전 직원은 신분증을 착용하고 채혈실을 찾은 모든 환자에게 친절한
            자세로 맞이하여야 한다.
            <br />
            전 직원은 찾아오신 모든 환자에게 다른 업무를 처리하고 있는 경우를
            제외하고는 대기시간을 최대한 짧게 하여야 한다.
            <br />
            소요일이 오래 걸리는 검사에 대해서는 결과가 나오는 날짜와 시간을
            알려드려야 한다.
          </div>
          <div className={styles.sub_num_wrapper}>
            <div className={styles.rect} />
            <div className={styles.sub_num_title}>
              2. 환자가 전화로 검사문의를 하였을때
            </div>
          </div>
          <div className={styles.sub_num_text}>
            “인사말, 소속, 이름”을 정중히 밝히고 문의에 답하여야 한다.
            <br />
            담당자가 부재중이라도 최선을 다해 답해드리고 미처리 부분에 대해서는
            담당자가 직접 전화를 드릴 수 있도록 메모를 남긴 후<br />
            빠른 시간 안에 조치하도록 한다.
          </div>
          <div className={styles.sub_num_wrapper}>
            <div className={styles.rect} />
            <div className={styles.sub_num_title}>
              3. 채혈 대기 중 환자가 불만을 표현할 때
            </div>
          </div>
          <div className={styles.sub_num_text}>
            의료기관 책임자는 환자를 채혈 대기실 공간에서 분리하여 단둘이
            있을만한 곳으로 이동시키고
            <br />그 불편사항의 내용을 파악하고 설명해야하며 그 내용을
            상급자에게 반드시 보고해야 한다.
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.section_title}
        >
          채혈절차
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.section_num_wrapper}
        >
          <div className={styles.section_num_content}>
            <div className={styles.section_num_title}>1. 채혈접수</div>
            <div className={styles.section_num_text}>
              - 환자의 등록번호가 있는 진료카드나 영수증을 이용하여 환자본인임을
              확인하고 접수한다.
              <br />
              - 검사항목에 맞는 적절한 검체용기를 선택하여
              검진기록지(검사의뢰서)의 수검자이름, 주민등록번호, 접수번호와 같은
              <br />
              &nbsp;&nbsp;신원정보를 검진일/검체 채취일을 기록하고 검체의
              용기에도 동일한 인적 사항을 기록하여 식별을 용이하게 한다.
              <br />
              &nbsp;&nbsp;(또는 바코드를 발행시켜 올바르게 부착 시킨다.)
            </div>
          </div>
          <div className={styles.section_num_content}>
            <div className={styles.section_num_title}>2. 환자확인</div>
            <div className={styles.section_num_text}>
              - 환자의 이름을 직접 호명한다.
              <br />
              - 채혈의자에 앉으면 환자의 성명과 등록번호 또는 성별, 생년월일 등
              2가지 이상의 방법으로 환자의뢰지의 인적사항을
              <br />
              &nbsp;&nbsp;확인하며, 환자의 성함을 먼저 부르기 보다는 이름을 직접
              물어 확인한다.(동명이인구분)
            </div>
          </div>
          <div className={styles.section_num_content}>
            <div className={styles.section_num_title}>3. 검체채취</div>
            <div className={styles.section_num_text}>
              - 검사에 적합한 용기에 해당라벨(바코드)이 정확히 부착되었는지
              확인하고, 라벨지(바코드)에 채혈자 서명한다.
              <br />
              - (동일한 바코드를 사용해서 모 검체에서 분리된 자 검체를
              식별한다.)
              <br />- 친절하게 올바른 채혈방식에 따라 채혈한다.
            </div>
          </div>
        </div>
        <div className={styles.mobile_none}>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.section_title}
          >
            올바른 채혈 방법
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.sub_wrapper}
          >
            <div className={styles.sub_title_wrapper}>
              <div className={styles.rect} />
              <div className={styles.sub_title_text}>채혈</div>
            </div>
            <div className={styles.sub_text_wrapper}>
              <div className={styles.sub_text}>
                마스크 등 보호구를 착용해야 하며 환자채혈전 손씻기를 시행한다.{" "}
                <br />
                채혈 전 감염관리 안전관리 규칙에 준하여 손씻기를 해야 한다.{" "}
                <br />
                채혈실에서는 알코올 손소독제를 사용하는 법을 수행하며 그 방법은{" "}
                <br />
                다음과 같다. <br />
                <br />
                다음) 알코올 손소독제를 사용할 때 한 손바닥에 소독제를 <br />
                던 후 양손으로 소독제가 마를 때까지 손과 손가락 표면을 모두
                문지른다. <br />
                사용량은 일반적으로 엄지손톱만큼의 분량이며 제조회사의 <br />
                권장사항을 따른다.(그림 1) <br />
              </div>
              <img
                src="../imgs/img/blood-collection.png"
                alt=""
                className={styles.sub_img}
              />
            </div>

            <div className={styles.sub_num_wrapper}>
              <div className={styles.rect} />
              <div className={styles.sub_num_title}>1. 환자준비</div>
            </div>
            <div className={styles.sub_num_text}>
              환자의 혈액검체를 채취하기 전에 환자의 인적사항을 확인하여야 하며
              <br />
              환자는 편안하게 앉거나 누운 상태에서 검체를 채취한다. 불안해하는
              환자는 눕도록 하는 것이 좋다. <br />
              정맥천자 시 사용되는 의자는 편안해야 하며 의자에세 실신하는 환자를
              적게 하기 위해서 될 수 있으면 팔을 편하게 하고 미<br />
              끄러지지 않는 좌우 팔 받침대가 있는 의자를 사용한다. <br />
              정맥천자를 어린아이들이나 협조가 어려운 환자에게 시행할 경우에는
              정맥전차 할 부위(팔등)을 서서히 단단하게 <br />
              고정시킨 후 장갑을 착용한 다음 정맥천자를 시행한다. <br />
              정맥천자시의 주사바늘은 멸균된 것을 사용하여 채취과정 동안
              멸균상태를 유지한다. <br />
              <br />
              금식이 필요한 검사 (콜레스트렐, 혈당검사 등)가 처방된 환자인 경우
              8시간 이상 공복을 유지하였는지 확인하여야 한다.
            </div>
            <img
              src="../imgs/img/sub-num-table.svg"
              alt=""
              className={styles.sub_num_table}
            />
            <div className={styles.sub_num_text}>
              특정 음식물(또는 약물)등의 섭취를 금해야 하는 검사 처방인지를 확인
              후 채혈해야 한다. <br />
              채혈하기 전 환자에게 채혈 할 것을 설명해야 하며 환자의 이름을
              물어서 확인한다. <br />
              채혈하고자 하는 환자의 성명과와 수검자의 검진 기록지의 인적사항을
              반드시 확인하고, <br />
              환자가 확인 된 다음에는 바코드가 부착된 튜브의 정보를 다시 한 번
              확인한다. <br />
              환자의 팔을 책상 위(심장 위치와 같은)에 올려놓고 힘을 뺀 상태로
              손바닥이 위를 향하게 (supination)하며, <br />
              주관절은 되도록 신전상태가 되어야 하며 경우에 따라서는 팔꿈치 밑에
              받을 만한 물건을 놓아 주는 것이 좋다. <br />
              정맥주사용 주사제를 맞고 있는 경우는 반대쪽 혈관을 이용해야 한다.{" "}
              <br />
              혈액응고검사가 있는 경우 수액(생리적 항응고제등)을 맞고 있는
              쪽에서는 절대로 채혈을 하여서는 안 되며, <br />
              조직액이 삽입될 수 있으므로 채혈 시 첫 번째 튜브로 검체를
              삽입하여서는 안 된다. <br />
              채혈을 마친 후 환자와 검체를 다시 한 번 확인하여야 한다.
            </div>
            <div className={styles.sub_num_wrapper}>
              <div className={styles.rect} />
              <div className={styles.sub_num_title}>
                2.정맥주사를 맞고 있는 라인에서 채혈을 해야 하는 경우 fluid
                주입을 막은 상태에서 먼저 충분한 양<br />
                (약 30㎖ 정도)의 혈액을 빼 낸 후 혈액을 채취한 후 바코드가
                부착된 튜브에 검체를 담아야 한다.
              </div>
            </div>
            <div className={styles.sub_num_wrapper}>
              <div className={styles.rect} />
              <div className={styles.sub_num_title}>3. 주사기 준비</div>
            </div>
            <div className={styles.sub_num_text}>
              주사기를 사용할 경우 주사바늘 뚜껑이 닫힌 채로 주사기 몸체와
              바르게 결합되어 있는지 확인한 다음 플런저를 한 번<br />
              살짝 잡아당겨 보아 부드럽게 움직이는지 확인한다. <br />
              다량의 혈액을 주사기로 이용할 경우 진공채혈 주사기나 나비바늘을
              이용한다.
            </div>
            <div className={styles.sub_num_wrapper}>
              <div className={styles.rect} />
              <div className={styles.sub_num_title}>4. 채혈시기</div>
            </div>
            <div className={styles.sub_num_text}>
              의심되는 질환의 진단 가능성이 가장 높은 상태에서 채혈해야 한다.{" "}
              <br />
              발열기 채혈 &nbsp;&nbsp;&nbsp;가) 말라리아 진단을 위한 혈액도말{" "}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나)
              아급성 심내막염 진단을 위한 혈액배양의 경우 <br />
              식이에 영향을 받는 혈당이나 중성지방검사는 식전에 채혈 <br />
              일중변동이 있는 호르몬 검사 <br />
              투약시간과의 연관성이 중요한 약물검사 <br />
              일상뇨 검사: 아침 첫 소변(중간소변) <br />
              호르몬이나 단백질, 전해질 정량을 검사하기 위한 24시간 뇨 검사:
              첫날 오전 7시에 방광을 완전히 비우고 나서 <br />
              수집을 시작하여 다음날 오전 7시의 마지막 요까지 완전하게
              모아야한다.
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.tips_wrapper}
          >
            <img
              src="../imgs/img/blood-tips.png"
              alt=""
              className={styles.tips_img}
            />
            <div className={styles.tips_bold}>
              구혈대(Tourniquet)을 상박둘레에 맨다. 구혈대는 한번만 잡아 당기면
              잘 풀릴 수 있게 묶는다.
              <br />
              채혈하고자 하는 위치에서 10cm 이상 떨어진 곳에 묶어야 한다.
            </div>
            <div className={styles.tips_text}>
              육안적으로 확인하고 촉진으로 확인하여 적당한 정맥을 고른다. <br />
              좀 더 쉽게 정맥이 부풀어 오르게 하려면 환자는 주먹을 몇 번 쥐었다
              편다. <br />
              <br />
              알코올 솜으로 여러 번 문질러 천자할 부위를 닦아내고 알코올이
              마르도록 자시 내버려둔다.(30-60 seconds) <br />
              구혈대는 2분 이상 묶지 말아야 하며, 2분내 채혈을 마치지 못한 경우
              구혈대를 풀었다 다시 묶어 주어야 한다. <br />
              구혈대를 6분 이상 둔 경우 아래와 같은 상황이 발생된다. <br />
              가) Hemoconcentration (혈액농축) <br />
              너무 오랫동안 구혈대(Tourniquet)를 묶거나 너무 세게 묶었을 경우{" "}
              <br />
              나) 구혈대(Tourniquet)를 6분 이상 묶었을 경우 : <br />
              Enzymes, bilirubin, albumin, total protein, cholesterol (6-10%
              증가) <br />
              Triglyceride, iron, calcium, RBC, hemoglobin (3-6% 증가) <br />
              Potassium, Chloride, Creatinine, Urea, Glucose, Leukocytes,
              Inorganic, Phosphate (5% 감소) <br />
              다) 구혈대(Tourniquet)를 5분 묶었을 경우 : Cholesterol 5-20%증가{" "}
              <br />
              <br />
              - 진공채혈관을 이용한 정맥천자 <br />
              ① 바늘(vacutainer needle)의 녹색부위를 왼속으로 잡고 오른손으로
              위의 반투명한 뚜껑을 돌려 제거하면 <br />
              &nbsp;&nbsp;&nbsp;adapter 속으로 들어갈 고무로 피막 된 짧은 바늘이
              나타나는데, <br />
              &nbsp;&nbsp;&nbsp;adapter의 좁은 쪽 입구로 돌려 끼워넣어 움직이지
              않도록 고정시킨다. <br />
              ② 진공채혈관을 고무뚜껑이 짧은 바늘 쪽으로 향하도록 adapter의 큰
              쪽 입구를 통해 넣되 <br />
              &nbsp;&nbsp;&nbsp;고무뚜껑이 바늘에 찔리지 않도록 느슨하게 둔다.{" "}
              <br />
              ③ 주사기를 사용할 때와 같은 요령으로 정맥을 찾아 찌르면 감각으로
              정맥에 바늘이 들어갔다는 것을 알 수 있으며, <br />
              &nbsp;&nbsp;&nbsp;이때 주사기를 지지하던 요령으로 왼손을 사용하여
              adapter 사이에 adapter를 끼우고 엄지손가락으로 <br />
              &nbsp;&nbsp;&nbsp;진공채혈관을 작은 바늘 쪽으로 힘껏 밀어넣어
              뚜껑이 바늘에 의해 뚫림과 동시에 혈액이 채혈관 안으로 채취된다.
              <br />
              ④ 진공채혈관은 목적에 따라 적당한 압력으로 내압이 조정되어
              있으므로 <br />
              &nbsp;&nbsp;&nbsp;일정량의 혈액이 얻어지면 더 이상 혈액이 나오지
              않는다. <br />
              &nbsp;&nbsp;&nbsp;따라서 이때 왼손은 그대로 두고 가볍게 채혈관을
              adapter로부터 분리하면 된다. <br />
              ⑤ 채혈을 더 할것이면 새로운 진공채혈관만 adapter 속으로 넣고 힘껏
              밀어 주면 된다. <br />
              &nbsp;&nbsp;&nbsp;채혈관을 10회 이상 뒤집어 항응고제가 섞이게
              한다. <br />
              ⑥ 채혈관은 반드시 labeling이 되어 있는 것을 사용하여야 하며
              채혈되는 동안 이름을 확인 할 수 있어 <br />
              &nbsp;&nbsp;&nbsp;매 튜브마다 확인해야 한다. <br />
              <br />
              - 주사기를 이용하여 채혈하는 경우 <br />
              ① 일반적으로 21G 바늘이 연결되어있는 주사기를 사용한다. <br />
              ② 채혈하고자 하는 부위에서 3~5cm 아래쪽을 누르며 혈관을 팽팽히
              당겨 고장시켜 찌르기 쉽게 하고
              <br />
              &nbsp;&nbsp;&nbsp;환자의 불편함도 감소시킨다. <br />
              ③ 알코올이 건조된 후 바늘의 사면을 위로하여 15℃정도로 약 1cm 정도
              찌른다. <br />
              &nbsp;&nbsp;&nbsp;만약 너무 깊게 찌르면 혈관벽이 천공될 위험이
              있다. <br />
              &nbsp;&nbsp;&nbsp;만약 동맥천공이 의심되면 바늘을 재빨리 제거한 후
              적어도 5분이상 출혈이 멈출 때까지 해당부위를 강하게 압박하고{" "}
              <br />
              &nbsp;&nbsp;&nbsp;담당의에게 즉시 연락한다. <br />
              ④ 일반혈관의 경우 채혈관에 혈류가 유입되기 시작하면 곧바로
              지혈대를 풀고 환자의 주먹을 펴도록 한다. <br />
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.sub_wrapper}
        >
          <div className={styles.sub_num_text}>
            검체가 많을 때 일반적인 튜브별 채혈하는 순서는 아래와 같다.
          </div>
          <div className={styles.sub_tube_wrapper}>
            <img
              src="../imgs/img/blood-tubes.png"
              alt=""
              className={styles.sub_tube_img}
            />
          </div>
          <div className={styles.sub_num_text}>
            채혈을 마친 튜브는 튜브별 특징에 맞게 mix하여야 한다.
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.mixing_title}
        >
          Mixing / 믹싱
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.mixing_content_wrapper}
        >
          <img
            src="../imgs/img/mixing.png"
            alt=""
            className={styles.mixing_img}
          />
          <div className={styles.mixing_text_wrapper}>
            <div className={styles.mixing_text_top}>
              Mixing 목적은 각 tube 안에 포함된 일정량의 시약 함유되어 있는 것을
              <br />
              mixing을 통해 tube 내 혈액과 잘 혼합되어 원활한 작용 유도한다.
              <br />
              Mixing 시기 : 채혈 후 곧바로 부드럽게 시행
              <br />
              Mixing 방법 : Tube를 똑바로 잡고 180˚ 아래로 내려 들어 올리면 1회
              실기,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;각
              검사 tube 별로 mixing 횟수를 반복
            </div>
            <div className={styles.mixing_text_bot}>
              부적절한 Mixing으로 인한 문제 : EDTA, Sodium citrate, Heparin
              tube- Clot,
              <br />
              SSTⅡ tube- 응고가 덜 된 상태 발생
            </div>
          </div>
        </div>
        <div className={styles.mobile_none}>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.section_title + " " + styles.mg_lft_50}
          >
            검체 채취용 용기 종류 및 혼합 방법
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.section_title + " " + styles.mg_lft_50}
          >
            검체채취과정
          </div>
          <img
            data-aos="fade-up"
            data-aos-duration="2000"
            src="../imgs/samples/sample-process.png"
            alt=""
            className={styles.extraction_img}
          />

          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.section_title + " " + styles.mg_lft_50}
          >
            검체 분리 및 보관
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className={styles.text_light + " " + styles.mg_lft_50}
          >
            -채취된 검체는 검사 전까지 각 검체별 특성에 맞게 보관해야 합니다.
            <br />
            -특별한 보관이 필요한 검사의 경우에는 검사안내서를 참고하여 보관하여
            주십시오.
            <br />
            -임상 화학(생화학) 및 면역 혈청 검사를 위해 분리한 혈청은 냉장
            보관해야 합니다.
            <br />
            -혈당 검사 시에는 30분 이내에 혈청을 분리해 두고 가급적 NaF 용기
            사용을 권장합니다. <br />
            -외부 기관으로 위탁하는 혈청 검체의 경우, 운송 과정에서 냉장 상태를
            유지해야 합니다.
            <br />
            -혈액학 검사용 검체는 Roller-mix나 Manual Inversion에 관계없이
            충분히 혼합되어야 하며 검사 직전에도 충분히 혼합한 후 검사를
            실시합니다.
            <br />
            -응고 검사용 검체의 경우 혈장을 분리하여 냉장 보관하다가 검사해야
            합니다.
            <br />
            -소변 검사는 냉장 보관을 하지 않고 2시간 이내에 검사를 완료해야
            합니다.
            <br />
            -채혈된 검체를 원래의 용기로부터 분주하여 사용하는 경우, 모든
            자(子)검체에 해당 환자의 검체임을 확인할 수 있는 자(子)검체에 대한
            식별표시가 있어야 합니다. 모(母)검체에 특정 번호(접수번호, 검체번호,
            차트번호 등)를 기재하고 동일한 번호를 자검체에 표시함으로써 당일
            자(子)검체 간의 혼동이 없어야 합니다.
            <br />
            -검사가 끝난 검체는 검사 결과의 재검사 등 기타의 사유로 일정 기간
            보관 후 폐기물관리법에 따라 폐기합니다. (화학검사용 검체: 7일,
            혈액학 검사용 검체: 3일)
          </div>

          <div
            className={styles.text_blue}
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <br />▶ 주의 : 보관 검체로 검사 진행된 항목의 경우 보관 상태에 따라
            결과 값에 영향을 줄 수 있으므로
            <br />
            &nbsp;&nbsp;&nbsp;이점을 의뢰의료기관 담당자에게 알려 적절한 조치를
            취하게 한다.
          </div>

          <img
            data-aos="fade-up"
            data-aos-duration="2000"
            src={require("../../../assets/imgs/sample-handling-1.png")}
            alt="1"
            className={styles.sample_handling_1}
          />
        </div>
        <SectionTitle />
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.section_title}
        >
          의료관련감염 표준예방지침 요약
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className={styles.text_normal_500}
        >
          감염 예방 일반지침에는 감염관리체계 및 프로그램, 무균술, 손위생, 감염
          예방을 위한 주사실무를 위한 권고를 포함하고 있다. 이러한 감염 예방
          권고사항들을 병원 내 감염관리실뿐만 아니라 병원의 최고 경영진에서부터
          환경관리인까지 병원직원 모두가 본인의 책임으로 인식하고 수행할 때 보다
          효율적으로 의료관련감염의 예방을 달성할 수 있다.
        </div>
        <div
          className={styles.sub_num_wrapper_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.rect} />
          <div className={styles.sub_num_title}>표준주의 권고 요약</div>
        </div>
        <div
          className={styles.sub_num_text_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          표준주의는 의료기관 내에서 환자를 대상으로 하는 모든 처치와 술기,
          간호를 하는 데 가장 기본적인 지침이다. <br />
          환자의 혈액, 체액, 분비물, 배설물, 손상된 피부와 점막을 다룰 때
          표준주의에 따라 환자를 진료하여 의료인 스스로를 <br />
          보호하며 환자의 안전을 도모해야 한다. 전파경로별 주의에는 접촉주의,
          비말주의, 공기주의가 있다. <br />
          이러한 모든 격리지침을 준수할 때 기본적으로 표준주의에 각각의
          전파경로별 주의를 추가하여 준수해야 한다.
          <br />
          <br />
          1) 표준주의 일반원칙
          <br />
          <br />
          - 감염병 증상이 있는 환자는 전파 가능성이 있는지에 대해 적절한 시기에
          평가한다.
          <br />
          - 모든 환자를 대상으로 표준주의를 준수한다.
          <br />
          - 의료기관은 의료종사자들을 대상으로 다음의 사항에 대해 정기적으로
          교육한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 전파의 위험도 평가, 개인보호구 선택과 사용,
          효과적인 손위생 방법, 표준주의 지침
          <br />
          <br />
          2) 표준주의：호흡기 예절
          <br />
          <br />
          - 의료종사자들은 환자와 가족, 방문객을 대상으로 손위생과 호흡기 예절에
          대해 안내한다.
          <br />
          - 병원 입구와 눈에 잘 띄는 장소에 호흡기 예절과 관련한 포스터를
          게시한다. 호흡기 예절은 아래와 같다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 기침이나 재채기를 할 때 입과 코를 휴지로
          가리고, 사용한 휴지는 바로 휴지통에 버리고, 휴지가 없다면 옷소매를
          이용하도록 한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 마스크를 착용하고, 다른 사람으로부터 고개를
          돌려 기침이나 재채기를 하도록 한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 다른 환자와 1m 이상 거리를 유지한다.
          <br />
          - 병동과 외래의 대기 장소에는 손 위생과 관련한 물품을 제공하고 손 위생
          방법을 안내한다.
          <br />
          - 급성 호흡기 감염 증상이 있는 환자와 동반인이 의료기관에서 초기에
          접하는 장소(출입구, 선별구역, 접수창구, 대기장소 등)에서부터 <br />
          &nbsp;&nbsp;&nbsp;호흡기 예절을 준수하도록 안내한다.
          <br />
          <br />
          3) 표준주의：환자의 이동과 배치
          <br />
          <br />
          - 다른 사람들에게 감염을 전파할 위험이 있는 환자의 경우 전파될 수 있는
          가능성을 고려하여 가능한 한 1인실에 두도록 한다.
          <br />
          - 1인실이 여유가 없는 경우에는 다음 사항을 고려하여 우선순위를
          결정한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 가능한 감염 전파경로
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 추가 주의 조치가 필요한 감염 유무
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 환경오염 정도와 주의 조치를 지키기 어려운
          상태의 정도
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 분비물 또는 배설물의 조절 가능 유무
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 다른 환자에게 전파될 경우 파급 효과의 크기
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 병실을 같이 사용할 수 있는 방법
          <br />
          - 의학적으로 필요한 경우를 제외하고 환자를 의료기관 내, 그리고
          의료기관 간 이송하는 것을 되도록 피한다.
          <br />
          <br />
          4) 표준주의：치료장비와 기구관리
          <br />
          <br />
          - 혈액이나 체액으로 오염될 수 있는 장비와 기구의 설치, 이동, 관리에
          대한 지침과 정책을 수립한다.
          <br />
          - 혈액이나 체액에 오염되었거나 오염이 의심되는 장비와 기구를 다룰
          때에는 예상되는 오염 수준에 따라 개인보호구를 착용한다.
          <br />
          <br />
          5) 표준주의：환경관리
          <br />
          <br />
          - 환자의 접촉 수준과 오염 정도에 따라 환경 청소지침과 정책을 수립한다.
          <br />
          - 환자와 가까운 거리에 있거나 환자가 자주 만지는 물건과 환경 표면은
          병원균으로 오염될 가능성이 높기 때문에 자주 청소하고 소독한다.
          <br />
          - 병원 환경은 육안적으로 깨끗해야 한다. 필요하지 않은 물품과 장비가
          없어야 하며 물품이나 환경의 표면에는 먼지와 흙이 없어야 한다.
          <br />
          - 소독제는 허가 기관의 공인된 것을 사용하고 제조사의 사용지침을 따라야
          한다.
          <br />
          - 유행상황에서 환경소독제를 사용 함에도 불구하고 환경오염으로 인한
          전파의 가능성이 높은 경우는 사용 중인 소독제에 내성이 있는지 <br />
          &nbsp;&nbsp;&nbsp;고려하여 다른 소독제로 변경할지를 검토한다.
          <br />
          - 의료기관 내 소아 구역 혹은 대기 공간에서 아이들을 위한 시설,
          장난감에 대한 정기적 청소와 소독에 대한 지침/정책을 수립한다.
          <br />
          - 공용으로 사용하는 장난감 관리에 대한 정책과 지침 수립 시 다음의
          내용을 포함할 수 있다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 세척과 소독이 용이한 장난감을 사용한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 털이 있는 장난감은 비치하지 않는다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 대형 고정식 장난감은 적어도 매주 또는 눈에
          띄게 더러워진 경우에는 바로 청소하고 소독한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 장난감을 입에 댈 경우에는 소독한 후 물로
          충분히 헹궈준다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 장난감 세척 및 소독이 필요한 경우 즉시
          시행하거나 다른 장난감과 분리하여 별도로 지정된 라벨이 붙어 있는
          용기에 보관한다.
          <br />
          - 모든 의료 종사자들이 위생적이고 안전한 진료 환경의 유지 중요성에
          대하여 교육을 받고 환경과 장비의 청소와 오염제거에 책임감을 <br />
          &nbsp;&nbsp;&nbsp;가지도록 한다.
          <br />- 병원균에 의한 환경오염이 감염의 확산과 관련이 의심되는 경우
          청소 수준을 높인다.
        </div>

        <div
          className={styles.sub_num_wrapper_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.rect} />
          <div className={styles.sub_num_title}>접촉주의 일반원칙 요약</div>
        </div>
        <div
          className={styles.sub_num_text_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          환자나 그 주변 환경과 직접 또는 간접적인 접촉으로 병원균이 전파되는
          경우에는 표준주의와 함께 접촉주의를 추가로 적용한다.
        </div>

        <div
          className={styles.sub_num_wrapper_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.rect} />
          <div className={styles.sub_num_title}>비말주의 일반원칙 요약</div>
        </div>
        <div
          className={styles.sub_num_text_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          - 기침, 재채기, 대화 중 호흡기 비말로 병원체가 전파되는 경우는
          표준주의에 추가로 비말주의를 적용한다.
          <br />
          - 비말주의가 필요한 환자를 선별하기 위하여 입구나 잘 보이는 장소에
          안내문을 비치한다.
          <br />
          - 의료종사자들은 자가 오염을 방지하기 위하여 자신의 눈, 코, 입의
          점막을 손으로 만지지 않는다.
          <br />- 유행성이하선염이나 풍진에 면역력이 없는 의료종사자는 이러한
          감염을 앓고 있는 환자의 진료에 참여하지 않도록 한다.
        </div>

        <div
          className={styles.sub_num_wrapper_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.rect} />
          <div className={styles.sub_num_title}>공기주의 일반원칙 요약</div>
        </div>
        <div
          className={styles.sub_num_text_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          - 사람 간 공기 전파가 가능한 병원체에 감염되었거나 의심되는 경우에는
          표준주의와 함께 공기주의를 적용한다.
          <br />
          - 병실 입구나 다른 잘 보이는 곳에 공기주의가 필요하다는 표시를 한다.
          <br />
          - 공기주의를 지켜야 하는 감염병에서 에어로졸이 형성될 수 있는 시술을
          시행할 경우에는 다음의 주의사항을 따라야한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 의학적으로 필요한 경우에만 시술을 하고,
          계획적으로 시술을 시행하고, 적절한 안정제를 사용한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 시술에 참여하는 의료종사자 수를 제한한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 가능한 한 공기주의 격리실에서 시행한다.
          공기주의 격리실이 없다면 밀폐된 상태로 시행한다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;∙ 시술 중 충분한 환기를 해야 하고, 참여하는
          모든 의료종사자는 N95 마스크를 착용한다. 가능하다면 폐쇄형 기도흡인을
          시행한다.
          <br />
          - 공기주의가 필요한 환자가 삽관을 하거나 인공호흡기를 적용 중인 경우
          기계와 주위 공기가 오염되는 것을 방지하기 위해 튜브에 적절한
          <br />
          &nbsp;&nbsp;&nbsp;세균 필터를 장착하고, 가능한 한 폐쇄형 기도흡인을
          시행한다.
          <br />- 공기주의가 필요한 환자가 퇴원 후 병실 청소 시 공기 중에
          에어로졸이 없어질 때까지 충분한 시간이 지난 후에 청소를 한다.
        </div>

        <div
          className={styles.sub_num_wrapper_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.rect} />
          <div className={styles.sub_num_title}>개인보호구 일반원칙 요약</div>
        </div>
        <div
          className={styles.sub_num_text_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          - 환자의 혈액이나 체액과 접촉할 가능성이 있을 경우에는 개인보호구를
          착용한다.
          <br />
          - 개인보호구를 벗는 과정에서 옷이나 피부가 오염되지 않도록 주의한다.
          <br />
          - 병실을 나가기 전에 개인보호구를 벗고 해당 물품을 버리고 나와야 한다.
          <br />
          - 개인보호구는 환자에게 병원체가 전파될 위험성과 의료종사자의 옷으로
          오염될 가능성을 고려하여 선택한다.
          <br />
          - 필요하다고 판단되는 경우 언제라도 착용이 가능하도록 개인보호구를
          지급한다.
          <br />- 개인보호구는 장갑, 앞치마 또는 가운, 고글, 마스크 순서로
          벗는다. 개인보호구를 제거한 후에는 손 위생을 수행한다.
        </div>
        <div
          className={styles.sub_num_wrapper_bot}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className={styles.rect} />
          <div className={styles.sub_num_title}>손 위생</div>
        </div>
        <img
          data-aos="fade-up"
          data-aos-duration="2000"
          src={require("../../../assets/imgs/sample-handling-bot-1.png")}
          alt="1"
          className={styles.sample_handling_bot}
        />
        <img
          data-aos="fade-up"
          data-aos-duration="2000"
          src={require("../../../assets/imgs/sample-handling-bot-2.png")}
          alt="1"
          className={styles.sample_handling_bot}
        />
      </div>
    </div>
  );
};

export default BloodCollection;
