import React, { useState, useEffect } from "react";
import styles from "./css/DataRoom.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import axios from "axios";
import printJs from "print-js";

const printImage = (img) => {
  printJs(img, "image");
};

const downloadFile = (file) => {
  var browserName = undefined;
  var userAgent = navigator.userAgent;

  switch (true) {
    case /Trident|MSIE/.test(userAgent):
      browserName = "ie";
      break;

    case /Edge/.test(userAgent):
      browserName = "edge";
      break;

    case /Chrome/.test(userAgent):
      browserName = "chrome";
      break;

    case /Safari/.test(userAgent):
      browserName = "safari";
      break;

    case /Firefox/.test(userAgent):
      browserName = "firefox";
      break;

    case /Opera/.test(userAgent):
      browserName = "opera";
      break;

    default:
      browserName = "unknown";
  }

  var isUrl = file.indexOf("http") > -1;

  if (browserName === "ie" || browserName === "edge") {
    //ie11
    var _window = window.open(
      isUrl ? file : "https://api.shinwon.org/media/" + file,
      "_blank"
    );
    _window.document.close();
    _window.document.execCommand(
      "SaveAs",
      true,
      file.split("/").slice(-1)[0] || file
    );
    _window.close();
  } else {
    axios({
      url: isUrl ? file : "https://api.shinwon.org/media/" + file,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.split("/").slice(-1)[0]);
      document.body.appendChild(link);
      link.click();
    });
  }
};

const DataContent = ({ link, title, img }) => {
  return (
    <div
      className={styles.academicfile_wrapper}
      onClick={() => window.open(link, "_blank")}
    >
      <div className={styles.datacontent_img_wrapper_big}>
        <img src={img} alt="" className={styles.datacontent_img_big} />
      </div>
      {Array.isArray()}
      <div className={styles.datacontent_title_big}>
        {Array.isArray(title) ? title.map((t) => t).join("\r\n") : title}
      </div>
    </div>
  );
};

const LeafletContent = ({ link, title, img }) => {
  return (
    <div
      className={styles.leaflet_wrapper}
      onClick={() => window.open(link, "_blank")}
    >
      <div className={styles.datacontent_img_wrapper_big}>
        <img src={img} alt="" className={styles.datacontent_img_big_lefleat} />
      </div>
      {Array.isArray()}
      <div className={styles.datacontent_title_big}>
        {Array.isArray(title) ? title.map((t) => t).join("\r\n") : title}
      </div>
    </div>
  );
};

const AcademicFile = () => {
  const [num, setNum] = useState(1);

  const data = [
    {
      link: "https://shinwon-ebook.s3.ap-northeast-2.amazonaws.com/content/index.html",
      title: "2024 검사안내",
      img: require("../../../assets/imgs/academicfile_1.png"),
    },
    {
      link: "https://shinwon-ebook2.s3.ap-northeast-2.amazonaws.com/content/index.html",
      title: "2023 검사안내",
      img: require("../../../assets/imgs/academicfile_2.png"),
    },
  ];

  const data2 = [
    {
      link: "https://shinwon-letter.s3.ap-northeast-2.amazonaws.com/%EC%95%8C%EC%B8%A0%ED%95%98%EC%9D%B4%EB%A8%B8%EB%B3%91+%EC%9C%84%ED%97%98%EB%8F%84+%EA%B2%80%EC%82%AC/content/index.html",
      title: "알츠하이머병 위험도 검사",
      img: require("../../../assets/imgs/aca1.jpg"),
    },
    {
      link: "https://shinwon-letter.s3.ap-northeast-2.amazonaws.com/%EB%B0%A9%EA%B4%91%EC%95%94+%EC%A1%B0%EA%B8%B0%EC%A7%84%EB%8B%A8%EC%9D%84+%EC%9C%84%ED%95%9C+%EB%B9%84%EC%B9%A8%EC%8A%B5%EC%A0%81+%EC%84%A0%EB%B3%84%EA%B2%80%EC%82%AC+NMP22/content/index.html",
      title: ["방광암 조기진단을 위한", "비침습적 선별검사 NMP22"],
      img: require("../../../assets/imgs/aca2.jpg"),
    },
    {
      link: "https://shinwon-letter.s3.ap-northeast-2.amazonaws.com/%EC%A0%84%EC%9E%90%EA%B0%84%EC%A6%9D+%EC%9C%84%ED%97%98%EB%8F%84+%EA%B2%80%EC%82%AC/content/index.html",
      title: "전자간증 위험도 검사",
      img: require("../../../assets/imgs/aca3.jpg"),
    },
    {
      link: "https://shinwon-letter.s3.ap-northeast-2.amazonaws.com/%ED%98%B8%ED%9D%A1%EA%B8%B0+%EA%B0%90%EC%97%BC+%EA%B2%80%EC%82%AC/content/index.html",
      title: "호흡기 감염 검사",
      img: require("../../../assets/imgs/aca4.jpg"),
    },
    {
      link: "https://shinwon-letter.s3.ap-northeast-2.amazonaws.com/%EA%B0%84+%EC%84%AC%EC%9C%A0%ED%99%94+%ED%98%88%EC%95%A1+%EB%A7%88%EC%BB%A4+M2BPGi/content/index.html",
      title: "간 섬유화 혈액 마커 M2BPGi",
      img: require("../../../assets/imgs/aca5.jpg"),
    },
    {
      link: "https://shinwon-letter.s3.ap-northeast-2.amazonaws.com/%EA%B0%84%EC%A7%88%EC%84%B1+%ED%8F%90%EC%A7%88%ED%99%98(ILD)/content/index.html",
      title: ["간질성 폐질환(ILD), 폐 섬유화", "보조 진단을 위한 KL-6"],
      img: require("../../../assets/imgs/aca6.jpg"),
    },
  ];

  const data3 = [
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/INTERGR/content/index.html",
      img: require("../components/css/img/leaflet/01 인터그레이티드 테스트_1.png"),
      title: ["인터그레이티드", "테스트"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/QUAD/content/index.html",
      img: require("../components/css/img/leaflet/02 쿼드검사_1.png"),
      title: "쿼드검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/ITST/content/index.html",
      img: require("../components/css/img/leaflet/03 통합산전 기형아 선별검사_1.png"),
      title: ["통합산전 기형아", "선별검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/BLDT/content/index.html",
      img: require("../components/css/img/leaflet/04 혈액 종합 검사_1.png"),
      title: "혈액 종합 검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/WILSON/content/index.html",
      img: require("../components/css/img/leaflet/05 윌슨병 선별 검사_1.png"),
      title: "윌슨병 선별 검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/FRAGX/content/index.html",
      img: require("../components/css/img/leaflet/06 취약X증후군선별검사.png"),
      title: ["취약X증후군", "선별검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/CER_CANC/content/index.html",
      img: require("../components/css/img/leaflet/07 자궁경부암 검사.png"),
      title: "자궁경부암 검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/AMH/content/index.html",
      img: require("../components/css/img/leaflet/08 난소기능검사.png"),
      title: "난소기능검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/SE_TR_IN/content/index.html",
      img: require("../components/css/img/leaflet/09 성 매개 감염증 검사.png"),
      title: "성 매개 감염증 검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/HELI/content/index.html",
      img: require("../components/css/img/leaflet/10 Helicobacter Pylori의 진단검사.png"),
      title: ["Helicobacter Pylori의", "진단검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/REST/content/index.html",
      img: require("../components/css/img/leaflet/11 호흡기 감염 검사.png"),
      title: "호흡기 감염 검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/ALLERGY/content/index.html",
      img: require("../components/css/img/leaflet/12 ALLERGY 검사안내.png"),
      title: "ALLERGY 검사안내",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/PRE_EL/content/index.html",
      img: require("../components/css/img/leaflet/13 전자간증 위험도 검사.png"),
      title: ["전자간증", "위험도 검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/NEWB/content/index.html",
      img: require("../components/css/img/leaflet/14 신생아 선천성 대사이상 선별검사.png"),
      title: ["신생아 선천성", "대사이상 선별검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/SARS/content/index.html",
      img: require("../components/css/img/leaflet/15 코로나 바이러스 항체 검사.png"),
      title: ["코로나 바이러스", "항체 검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/METAL_MIN/content/index.html",
      img: require("../components/css/img/leaflet/16 모발 중금속&미네랄.png"),
      title: "모발 중금속&미네랄",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/PROCAL/content/index.html",
      img: require("../components/css/img/leaflet/17 프로칼시토닌.png"),
      title: "프로칼시토닌",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/ROMA/content/index.html",
      img: require("../components/css/img/leaflet/18 로마검사.png"),
      title: "로마검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/M2BpGi/content/index.html",
      img: require("../components/css/img/leaflet/19 M2BpGi.png"),
      title: "M2BpGi",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/GLUTEN/content/index.html",
      img: require("../components/css/img/leaflet/20 글루텐면역반응검사.png"),
      title: "글루텐면역반응검사",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/ACUTE_DIA/content/index.html",
      img: require("../components/css/img/leaflet/21 급성설사 원인 바이러스.png"),
      title: ["급성설사 원인", "바이러스"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/KL-6/content/index.html",
      img: require("../components/css/img/leaflet/22 KL-6.png"),
      title: "KL-6",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/OLIGOME/content/index.html",
      img: require("../components/css/img/leaflet/23 알츠하이머병 위험도 검사.png"),
      title: ["알츠하이머병", "위험도 검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/TOTAL_IgG/content/index.html",
      img: require("../components/css/img/leaflet/24 IgG프로 90G Total IgG식품 면역반응 검사.png"),
      title: ["IgG프로 90G Total", "IgG식품 면역반응 검사"],
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/NMP22/content/index.html",
      img: require("../components/css/img/leaflet/25 NMP22.png"),
      title: "NMP22",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/NK/content/index.html",
      img: require("../components/css/img/leaflet/26 NK세포 활성도.png"),
      title: "NK세포 활성도",
    },
    {
      link: "https://shinwon-leaflet.s3.ap-northeast-2.amazonaws.com/PEDIA/content/index.html",
      img: require("../components/css/img/leaflet/27 소아청소년 건강검진.png"),
      title: "소아청소년 건강검진",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <SectionTitle kor="학술자료" eng="" />
        <div className={styles.title_normal}>
          의료법인 신원의료재단의 학술자료를 확인할 수 있습니다.
        </div>
        <div>
          <div className={styles.separation_wrapper}>
            <div
              onClick={() => setNum(1)}
              className={
                num === 1
                  ? styles.separation_selected
                  : styles.separation_content
              }
            >
              검사안내책자
            </div>
            <div
              onClick={() => setNum(2)}
              className={
                num === 2
                  ? styles.separation_selected
                  : styles.separation_content
              }
            >
              뉴스레터
            </div>
            <div
              onClick={() => setNum(3)}
              className={
                num === 3
                  ? styles.separation_selected
                  : styles.separation_content
              }
            >
              리플릿
            </div>
          </div>
        </div>
        <div
          className={
            num === 3 ? styles.datalist_wrapper3 : styles.datalist_wrapper2
          }
        >
          {num === 1
            ? data.map((dt) => <DataContent {...dt} />)
            : num === 2
            ? data2.map((dt) => <DataContent {...dt} />)
            : data3.map((dt) => <LeafletContent {...dt} />)}
        </div>
        <div className={styles.datalist_wrapper2}></div>
      </div>
    </div>
  );
};

export default AcademicFile;
