import React from "react";
import styles from "./css/Greeting.module.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const Greeting = () => {
  AOS.init();

  return (
    <div className={styles.container}>
      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        className={styles.greeting_top_wrapper}
      >
        <img
          src="../imgs/img/greeting-top.png"
          alt=""
          className={styles.greeting_top_img}
        />
      </div>
      <div className={styles.section}>
        <img
          className={styles.top_img}
          src={require("./css/img/top_president_img.png")}
          data-aos="fade-up"
          data-aos-duration="2000"
        />
        <div
          className={styles.top_content_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <img
            src={require("./css/img/message-president.png")}
            alt="message"
            className={styles.message}
          />
        </div>
        {/*<img
          className={styles.top_img}
          src={require("./css/img/top_doc_img.png")}
          data-aos="fade-up"
          data-aos-duration="2000"
        />
        <div
          className={styles.top_content_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <img
            src={require("./css/img/message-doctor-1.png")}
            alt="message"
            className={styles.message}
          />
        </div>

        <img
          className={styles.top_img}
          src={require("./css/img/top_doc_1.png")}
          data-aos="fade-up"
          data-aos-duration="2000"
        />
        <div
          className={styles.top_content_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <img
            src={require("./css/img/message-doctor-3.png")}
            alt="message"
            className={styles.message}
          />
        </div>

        <img
          className={styles.top_img}
          src={require("./css/img/top_doc2_img.png")}
          data-aos="fade-up"
          data-aos-duration="2000"
        />
        <div
          className={styles.top_content_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <img
            src={require("./css/img/message-doctor-2.png")}
            alt="message"
            className={styles.message}
          />
        </div>

        <img
          className={styles.top_img}
          src={require("./css/img/top_doc_3.png")}
          data-aos="fade-up"
          data-aos-duration="2000"
        />
        <div
          className={styles.top_content_box}
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <img
            src={require("./css/message-doctor-4.png")}
            alt="message"
            className={styles.message}
          />
        </div>*/}
      </div>
    </div>
  );
};

export default Greeting;
