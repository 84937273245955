import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import styles from "../../css/Irb.module.scss";
import InputForm from "../../components/InputForm";
import IrbSideBar from "./components/IrbSidebar";
import IrbIntro from "./components/IrbIntro";
import IrbRegister from "./components/IrbRegister";

function Irb(props) {
  const [num, setNum] = useState(1);

  useEffect(() => {
    setNum(parseInt(props.match.params.num));
  }, [props.match]);

  return (
    <Layout>
      <div className={styles.body}>
        <div className={styles.body_top_bg_wrapper}>
          <img
            src="../imgs/img/rnd.png"
            alt=""
            className={styles.body_top_bg}
          />
        </div>
        <div className={styles.body_contents}>
          <IrbSideBar selectedNum={num} />
          {num === 1 ? <IrbIntro /> : <IrbRegister />}
        </div>
      </div>
      <InputForm />
    </Layout>
  );
}

export default Irb;
