import React from "react";
import styles from "./css/IrbSidebar.module.scss";
import { Link } from "react-router-dom";

const IrbSideBar = ({ selectedNum }) => {
  return (
    <>
      <div className={styles.sidebar}>
        <div className={styles.side_box_shadow}>
          <div className={styles.sidebar_title}>
            <img
              src={require("./css/irb-sidebar.png")}
              alt=""
              className={styles.sidebar_title_img}
            />
            <div className={styles.sidebar_title_text}>IRB</div>
          </div>
          <a
            href="/irb/1#1"
            className={
              window.location.toString().split("#")[1] === "1"
                ? styles.sidebar_selected
                : styles.sidebar_content
            }
          >
            IRB 정의
          </a>
          <a
            href="/irb/1#2"
            className={
              window.location.toString().split("#")[1] === "2"
                ? styles.sidebar_selected
                : styles.sidebar_content
            }
          >
            심사대상
          </a>
          <a
            href="/irb/1#3"
            className={
              window.location.toString().split("#")[1] === "3"
                ? styles.sidebar_selected
                : styles.sidebar_content
            }
          >
            심사흐름도
          </a>
          <Link
            to="/irb/2"
            className={
              selectedNum === 2
                ? styles.sidebar_selected
                : styles.sidebar_content
            }
          >
            접수문의
          </Link>
        </div>
      </div>
    </>
  );
};

export default IrbSideBar;
