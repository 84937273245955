import React from "react";
import styles from "./css/IrbRegister.module.scss";
import SectionTitle from "../../../components/SectionTitle";

const IrbRegister = () => {
  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <SectionTitle kor="임상시험심사위원회" eng="IRB" />
        <div className={styles.title_normal}>
          의료법인 신원의료재단의 임상시험심사위원회 안내입니다.
        </div>

        <div className={styles.title}>IRB 접수문의</div>
        <div className={styles.sub_title}>
          IRB 관련 문의가 있을 경우 하단의 정보를 기입 후 당 재단의
          <span style={{ color: "black" }}> [담당자 이메일]</span>
          로 전송하여 주십시오.
          <br />
          정보가 확인되면 담당자의 답변을 받으실 수 있습니다.
        </div>

        <div className={styles.part_title}>
          담당부서 : 신원의료재단{" "}
          <b style={{ color: "#527ff8" }}>
            IRB행정부 (E-mail, irb@shinwon.org)
          </b>
        </div>

        <div className={styles.box}>
          <div className={styles.box_title}>의뢰자 기본정보</div>
          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>- 기관명:</div>
            <input className={styles.box_content_row_value} />
          </div>
          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>- 부서명:</div>
            <input className={styles.box_content_row_value} />
          </div>
          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>
              - 이&nbsp;&nbsp;&nbsp;름:
            </div>
            <input className={styles.box_content_row_value} />
          </div>
          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>- 연락처/이메일:</div>
            <input className={styles.box_content_row_value} />
          </div>

          <div className={styles.box_title} style={{ marginTop: 20 }}>
            문의내용
          </div>
          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>- 연구 유형:</div>
            <input className={styles.box_content_row_value} type="checkbox" />
            <div className={styles.box_content_checkbox_label_1}>
              임상적 성능시험
            </div>
            <input className={styles.box_content_row_value} type="checkbox" />
            <div className={styles.box_content_checkbox_label_2}>
              인체유래물연구(분석적 성능시험)
            </div>
            <input className={styles.box_content_row_value} type="checkbox" />
            <div className={styles.box_content_checkbox_label}>기타</div>
          </div>

          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>- 연구 목적:</div>
            <input className={styles.box_content_row_value} type="checkbox" />
            <div className={styles.box_content_checkbox_label_1}>
              국내 허가용
            </div>
            <input className={styles.box_content_row_value} type="checkbox" />
            <div className={styles.box_content_checkbox_label_2}>
              해외 수출 허가용
            </div>
            <input className={styles.box_content_row_value} type="checkbox" />
            <div className={styles.box_content_checkbox_label}>기타</div>
          </div>

          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>- 검사 항목:</div>
            <input className={styles.box_content_row_value} />
          </div>

          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>
              - 기타 사항: (검체 수량, 검체 용량, 검체 보관 조건 등)
            </div>
            <input className={styles.box_content_row_value} />
          </div>

          <div className={styles.box_title} style={{ marginTop: 20 }}>
            개인정보 수집 및 이용에 대한 동의
          </div>
          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>
              - 수집 목적: 서비스 이용에 따른 본인 확인 및 결과 회신, 서비스
              진행에 따른 필요 정보 획득 및 응대 목적
            </div>
          </div>

          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>
              - 수집 항목: 기관명, 부서명, 이름, 연락처, 이메일 주소, 연구 유형,
              연구 목적, 검사 항목
            </div>
          </div>

          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>
              - 보유 및 이용기간: 3개월 보관 후 폐기
            </div>
          </div>

          <div className={styles.box_content_row}>
            <div className={styles.box_content_row_key}>
              - 신원의료재단은 서비스 이용을 위해 필요한 최소한의 개인정보만을
              수집합니다.
            </div>
          </div>
        </div>

        <div className={styles.agree_box_row}>
          <div className={styles.agree_text}>
            위 개인정보 수집 및 이용에 동의 하십니까?(필수)
          </div>
          <input type="checkbox" style={{ marginLeft: 5 }} />
          <div className={styles.agree_text} style={{ marginLeft: 2 }}>
            네 동의합니다.
          </div>
          <input type="checkbox" style={{ marginLeft: 5 }} />
          <div className={styles.agree_text} style={{ marginLeft: 2 }}>
            아니오
          </div>
        </div>
        <div className={styles.shinwon_irb_wrapper}>
          <img
            src={require("../../../assets/imgs/shinwon_irb.png")}
            className={styles.shinwon_irb}
          />
        </div>
      </div>
    </div>
  );
};

export default IrbRegister;
